@import 'tailwindcss/base'; 
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

$color_1: white;
$color_2: #1f2d3d;
$color_3: black;
$color_4: whitesmoke;
$color_5: #007bfc;
$color_6: rgba(0, 0, 0, 0.88);
$color_7: #1677ff;
$color_8: #f5222d;
$font-family_1: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-family_2: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
$background-color_1: unset;
$background-color_2: #007bfc;
$background-color_3: white;
$background-color_4: transparent;

body {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-weight: 600;
  position: fixed;
  font-family: $font-family_1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $font-family_2;
}

.ant-col-offset-8 {
  margin-inline-start: 0px;
}

h1 {
  font-size: 36px;
}

h3.title {
  font-size: 24px;
  margin-top: 27px;
}

.login-page {
  background-image: url(../src/assets/img/polygon.svg);
}

.ant-btn {
  >span {
    display: inline-flex;
  }
}

.ant-input {
  height: 40px;
}

.ant-input-password {
  input {
    height: 38px;
  }
}

.ant-input-group {
  .ant-input-affix-wrapper {
    height: 40px;

    input {
      margin-top: -4px;
      background: none;
    }
  }
}

.ant-input-group-addon {
  .ant-btn {
    height: 40px;
  }
}

.anticon-menu {
  svg {
    width: 20px;
    height: 20px;
  }
}

.ant-alert-warning {
  background-color: $background-color_1;
}

.ant-switch {
  background: rgba(0, 0, 0, 0.45);
}

.ant-btn-primary {
  background-color: $background-color_2;
}

.ant-card-bordered {
  .ant-card-cover {
    margin-top: unset !important;
    margin-inline-start: unset !important;
    margin-inline-end: unset !important;
  }
}

.ant-layout {
  background: #fafafa !important;
}

.ant-layout-header {
  background: white;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.050980392156862744) !important;
  z-index: 1;
}

.ant-drawer-body {
  height: 100%;
  padding: 0 !important;
  overflow: hidden !important;
  background: #007bff linear-gradient(180deg, #268fff, #007bff) repeat-x !important;
}

.ant-drawer-close {
  color: $color_1 !important;
}

.ant-drawer-mask {
  background: unset !important;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.ant-menu {
  overflow-y: auto;
  background: #007bff linear-gradient(180deg, #268fff, #007bff) repeat-x !important;
}

div.ant-layout-sider-children {
  * {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #007bff linear-gradient(180deg, #268fff, #007bff) repeat-x !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #acb7bf;

      &:hover {
        background: #acb7bf;
      }
    }
  }
}

.ant-menu-sub {
  background: white !important;
  margin: 8px !important;
  border-radius: 8px !important;
  color: $color_2 !important;

  .ant-menu-item {
    padding-left: 16px !important;
    font-weight: 400;

    &:first-child {
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  .ant-menu-item-active {
    color: $color_2 !important;
    font-weight: 600;
  }
}

.ant-menu-item-only-child {
  .ant-menu-title-content {
    color: $color_3 !important;
  }
}

.ant-menu-submenu {
  ul {
    li {
      span {
        color: $color_3 !important;
      }
    }
  }
}

.ant-menu-item {
  color: $color_1 !important;
}

.ant-menu-submenu-title {
  span {
    color: $color_1 !important;
  }

  .ant-menu-title-content {
    color: $color_1 !important;
    color: $color_4;
  }
}

.ant-menu-item-group-title {
  padding-inline-start: 16px !important;
  color: $color_3 !important;
}

.ant-layout-sider {
  background: #007bff linear-gradient(180deg, #268fff, #007bff) repeat-x !important;
}

.ant-menu-item-icon {
  line-height: 48px !important;
  vertical-align: middle !important;

  svg {
    width: 18px;
    height: 18px;
  }
}

.anticon-menu-unfold {
  vertical-align: sub;

  svg {
    width: 18px;
    height: 18px;
  }
}

.anticon-menu-fold {
  vertical-align: sub;

  svg {
    width: 18px;
    height: 18px;
    vertical-align: sub !important;
  }
}

.ant-menu-item-active {
  background: transparent !important;
}

.ant-menu-item.ant-menu-item-selected {
  background-color: $background-color_3 !important;
  color: $color_5 !important;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.050980392156862744) !important;
}

.ant-card {
  cursor: pointer;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.050980392156862744) !important;
}

.ant-select-selection-item {
  font-size: 14px;
}

.select-with-button {
  .ant-select-selector {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.ant-card-meta {
  display: unset !important;
}

.ant-card-meta-avatar {
  padding-inline-end: 0px !important;
}

.ant-timeline-item.ant-timeline-item-last {
  padding-bottom: 0px;
}

.ant-card-meta-description {
  span {
    color: $color_6 !important;
  }
}

.ant-card-meta-title {
  span {
    color: $color_5 !important;
  }
}

.payment-modal {
  .ant-modal-content {
    .ant-modal-footer {
      text-align: center !important;
    }
  }
}

.footer-buy {
  position: fixed;
  bottom: 0;
  right: 16px;
  left: 240px;
  padding: 24px 50px;
  color: $color_6;
  background: #fafafa;
  margin-right: 10px;
}

.price-amount {
  color: $color_5;
  font-size: 16px;
  font-weight: 600;
}

.list-vps {
  .ant-modal-content {
    .ant-modal-body {
      .ant-tabs {
        .ant-tabs-content-holder {
          height: 650px;
          overflow: auto;
          padding: 16px;
        }
      }
    }
  }
}

.ant-tabs-extra-content {
  svg {
    width: 14px;
    height: 14px;
    width: 18px;
    height: 18px;
  }
}

.ant-table-cell {
  .anticon-copy {
    vertical-align: 0;
    align-self: center;
  }

  .anticon-setting {
    vertical-align: 0;
    align-self: center;
  }
}

.ant-table-wrapper {
  .ant-table-thead {
    >tr {
      >th {
        padding: 10px 16px;
      }
    }
  }

  .ant-table-tbody {
    >tr {
      >td {
        font-weight: 600;
      }
    }
  }
}

.ant-pagination-item-link {
  .anticon-left {
    vertical-align: 0 !important;
  }

  .anticon-right {
    vertical-align: 0 !important;
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item-icon {
      font-size: 14px;
    }
  }
}

.managament-tab {
  .ant-tabs-nav {
    margin-bottom: 0;
  }
}

.ant-table-thead {
  .ant-table-cell {
    background-color: $background-color_2 !important;
    color: $color_1 !important;
  }
}

.anticon.anticon-search {
  svg {
    vertical-align: inherit !important;
  }
}

.anticon.anticon-setting.ant-dropdown-trigger {
  svg {
    width: 18px;
    height: 18px;
  }
}

.anticon.anticon-copy {
  svg {
    width: 18px;
    height: 18px;
  }
}

.anticon.anticon-edit {
  svg {
    width: 18px;
    height: 18px;
  }
}

.ant-result-subtitle {
  a {
    color: $color_7;
  }
}

.ant-table-sticky-scroll {
  display: none !important;
}

.ant-collapse {
  background-color: $background-color_4;
}

.status-card {
  .ant-card-body {
    padding: 8px !important;
  }

  .ant-statistic-content-prefix {
    span {
      vertical-align: unset;
    }
  }
}

.action-vps-card {
  .ant-form-item {
    margin-bottom: 16px !important;
  }
}

.ant-table {
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.050980392156862744) !important;
}

.ant-card-head {
  border-bottom: none !important;
}

.ant-menu-title-content {
  font-size: 14px;
}

.ant-menu-mobile {

  .ant-drawer-wrapper-body {
    .ant-drawer-header {
      padding: 0;
    }
  }
}

.table-item-selected {
  .ant-table-tbody {
    color: $color_8;
  }

  .ant-table-thead {
    color: $color_8;
  }
}

.ant-collapse-expand-icon {
  color: $color_8 !important;
}

.shopping-card {
  vertical-align: top;
}

.appstore {
  vertical-align: top;
}

.ant-form-item-label {
  font-weight: 700;
}

.display_vps {
  .ant-select-selector {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}

.ant-select-item-option-active {
  background: #007bff linear-gradient(180deg, #268fff, #007bff) repeat-x !important;
  color: $color_1 !important;
}

.ant-select-dropdown {
  .ant-select-item {
    transition: none !important;
  }
}

.ant-layout-sider-trigger {
  background: #007bff linear-gradient(180deg, #268fff, #007bff) repeat-x !important;
}

.buying-description {
  .ant-descriptions-view {
    overflow: auto;
    max-height: 300px;
  }

  .ant-typography {
    &:last-child {
      margin-bottom: 0px !important;
    }
  }
}

.notification-icon-twotone {
  svg {
    width: 80px;
    height: 80px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.ant-result {
  .ant-result-subtitle {
    .result-buying-title {
      color: $color_8 !important;
    }
  }
}